import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import ListItemContent from '@alltrails/denali/components/ListItemContent';
import Location from '@alltrails/denali/icons/Location';
import { NearbyResult } from '../../types/algoliaResultTypes';
import styles from './styles/styles.module.scss';

type NearbyResultContentProps = {
  result: NearbyResult;
};

const NearbyResultContent = ({ result }: NearbyResultContentProps) => {
  const icon = (
    <div className={styles.iconContainer}>
      <Location color="--color-text-secondary" />
    </div>
  );

  return (
    <ListItemContent
      className={classNames(styles.listItemContent, styles.noDescription)}
      leftContent={icon}
      label={
        <div className={styles.label}>
          <FormattedMessage defaultMessage="Nearby" />
        </div>
      }
      testId={`algolia-search-${result.id}`}
    />
  );
};

export default NearbyResultContent;
