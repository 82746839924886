import { useCallback, useMemo, useState } from 'react';
import { AlgoliaResultType } from '@alltrails/shared/types/algoliaSearch';
import { useLocalStorage, removeItem } from '@alltrails/local-storage';
import { RecentAlgoliaHit } from '../types/algoliaResultTypes';

const storageKey = 'algolia-recent-searches';
const maxStored = 50;

const useRecentAlgoliaSearches = () => {
  const { getValue, setValue } = useLocalStorage<RecentAlgoliaHit[]>(storageKey, []);
  const [recentHits, setRecentHits] = useState(() => {
    const stored = getValue();
    if (stored) {
      // Validate stored items
      if (Array.isArray(stored)) {
        if (stored.find(item => !item || !item.type || !item.objectID)) {
          removeItem(storageKey);
        }
      }
    }
    return stored || [];
  });

  const updateRecentHits = useCallback(
    (hits: RecentAlgoliaHit[]) => {
      if (typeof window !== 'undefined') {
        setValue(hits);
        setRecentHits(hits);
      }
    },
    [setValue]
  );

  const getRecentAlgoliaHits = useCallback(
    (types: 'all' | AlgoliaResultType[], maxCount?: number) => {
      const filteredRecentHits = recentHits.filter(hit => types === 'all' || types.includes(hit.type));
      return maxCount ? filteredRecentHits.slice(0, maxCount) : filteredRecentHits;
    },
    [recentHits]
  );

  const addRecentAlgoliaHit = useCallback(
    (recentHit: RecentAlgoliaHit) => {
      if (recentHit) {
        const index = recentHits.findIndex(hit => hit && hit.objectID === recentHit.objectID);
        if (index > -1) {
          // Move the recent to the top of the list if it was already in the list
          updateRecentHits([recentHit, ...recentHits.slice(0, index), ...recentHits.slice(index + 1)]);
        } else {
          updateRecentHits([recentHit, ...recentHits.slice(0, maxStored - 1)]);
        }
      }
    },
    [recentHits, updateRecentHits]
  );

  const removeRecentAlgoliaHit = useCallback(
    (objectID: string) => {
      const index = recentHits.findIndex(hit => hit.objectID === objectID);
      if (index > -1) {
        updateRecentHits([...recentHits.slice(0, index), ...recentHits.slice(index + 1)]);
      }
    },
    [recentHits, updateRecentHits]
  );

  return useMemo(
    () => ({
      getRecentAlgoliaHits,
      addRecentAlgoliaHit,
      removeRecentAlgoliaHit
    }),
    [addRecentAlgoliaHit, getRecentAlgoliaHits, removeRecentAlgoliaHit]
  );
};

export default useRecentAlgoliaSearches;
