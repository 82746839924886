import Icon from '../components/Icon';

const DrawPath = Icon(
  'draw-path',
  <>
    <path
      fillRule="evenodd"
      d="M16.844 2.092a2.874 2.874 0 1 1 4.064 4.064l-4.013 4.01c-.326.326-.73.566-1.174.695l-2.87.837a1.248 1.248 0 0 1-1.55-1.55l.837-2.87c.13-.443.369-.847.696-1.174zm1.06 1.06-4.01 4.012a1.25 1.25 0 0 0-.316.534l-.71 2.432 2.433-.709c.201-.059.385-.167.534-.316l4.013-4.01a1.374 1.374 0 1 0-1.944-1.943M7.062 13.75a1.25 1.25 0 0 0 0 2.5h10.376a2.75 2.75 0 1 1 0 5.5H3a.75.75 0 0 1 0-1.5h14.438a1.25 1.25 0 1 0 0-2.5H7.062a2.75 2.75 0 0 1 0-5.5H12a.75.75 0 0 1 0 1.5z"
      clipRule="evenodd"
    />
  </>
);

export default DrawPath;
