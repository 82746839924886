import { useCallback, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useExperiments } from '@alltrails/experiments';
import { DropdownMenuTextOption } from '@alltrails/denali/types';
import useUser from '@alltrails/context/hooks/useUser';
import type Context from '@alltrails/core/types/Context';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import { useSelector } from 'react-redux';
import { AuthRequiredHeaderLink, HeaderLink, getHeaderLinks } from './headerLinks';

const SignUpModal = dynamic(() => import('@alltrails/user-auth').then(module => ({ default: module.SignUpModal })));

export type GetLinkInteractivity = (link: HeaderLink) => {
  linkInfo?: DropdownMenuTextOption['linkInfo'];
  onClick?: () => void;
};

const useHeaderLinks = () => {
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const inUS = (useSelector((state: { context: Context }) => state.context.countryId) ?? 313) === 313;
  const [authRequiredLink, setAuthRequiredLink] = useState<AuthRequiredHeaderLink>();
  const experiments = useExperiments();
  const hasByotExperiment = experiments?.['web-discover-byot-phase1-rm']?.value === 'treatment';

  const headerLinks = useMemo(
    () => getHeaderLinks(user, languageRegionCode, inUS, hasByotExperiment),
    [inUS, languageRegionCode, user, hasByotExperiment]
  );

  const getLinkInteractivity: GetLinkInteractivity = useCallback(
    link => {
      let linkInfo: DropdownMenuTextOption['linkInfo'];
      let onClick = link.onClick;

      if (link.requiresAuth) {
        if (user) {
          linkInfo = link.getLinkInfo(user);
        } else {
          onClick = () => {
            link.onClick?.();
            setAuthRequiredLink(link);
          };
        }
      } else {
        linkInfo = link.linkInfo;
      }

      return { linkInfo, onClick };
    },
    [user]
  );

  const signUpModal = useMemo(
    () => (
      <>
        {authRequiredLink && (
          <SignUpModal
            autoClose
            closeModal={() => setAuthRequiredLink(undefined)}
            hideInstallPrompt
            isOpen={Boolean(authRequiredLink)}
            onSuccess={loggedInUser => {
              if (authRequiredLink) {
                window.location.assign(authRequiredLink.getLinkInfo(loggedInUser).href);
              }
            }}
            trigger={authRequiredLink.trigger}
            upsellTrigger={authRequiredLink.upsellTrigger}
          />
        )}
      </>
    ),
    [authRequiredLink]
  );

  return useMemo(() => ({ headerLinks, getLinkInteractivity, signUpModal }), [headerLinks, getLinkInteractivity, signUpModal]);
};

export default useHeaderLinks;
