import type User from '@alltrails/core/types/User';

/**
 * isSubscriber checks that a user has a paid subscription.
 *
 * @param user User
 * @returns boolean
 */
export function isSubscriber(user?: User | null) {
  return ['peak', 'plus'].includes(user?.subscriptionTier || '');
}
