import classNames from 'classnames';
import ListItemContent from '@alltrails/denali/components/ListItemContent';
import Place from '@alltrails/denali/icons/Place';
import getBoldedTextElements from '../../utils/getBoldedTextElements';
import { StreetAddressResult } from '../../types/algoliaResultTypes';
import styles from './styles/styles.module.scss';

type StreetAddressResultContentProps = {
  query: string;
  result: StreetAddressResult;
};

const StreetAddressResultContent = ({ query, result }: StreetAddressResultContentProps) => {
  const label = getBoldedTextElements(result.name, query);
  const description = result.locationLabel ? getBoldedTextElements(result.locationLabel, query) : undefined;

  const icon = (
    <div className={styles.iconContainer}>
      <Place color="--color-text-secondary" />
    </div>
  );

  return (
    <ListItemContent
      className={classNames(styles.listItemContent, { [styles.noDescription]: !description })}
      leftContent={icon}
      label={<div className={styles.label}>{label}</div>}
      description={
        description ? (
          <div className={styles.description}>
            <span>{description}</span>
          </div>
        ) : null
      }
      testId={`address-search-${result.name}`}
    />
  );
};

export default StreetAddressResultContent;
