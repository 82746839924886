import { ComponentProps, KeyboardEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import FocusTrap from 'focus-trap-react';
import SearchInput, { type SearchInputProps } from '@alltrails/denali/components/SearchInput';
import { useScreenLock, ModalPortal } from '@alltrails/core';
import Link from '@alltrails/denali/components/Link';
import { ResultBase } from '../../types/searchBoxTypes';
import CustomSearchResults from './results/CustomSearchResults';
import styles from './styles/MobileSearch.module.scss';

type MobileSearchProps<T extends ResultBase> = {
  iconButtonProps?: SearchInputProps['iconButtonProps'];
  focusTrapOptions?: ComponentProps<typeof FocusTrap>['focusTrapOptions'];
  onCancel: () => void;
  onChange: (query: string) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  query?: string;
  testId: string;
} & Pick<
  ComponentProps<typeof CustomSearchResults<T>>,
  'activeId' | 'areResultsLoading' | 'emptyState' | 'onSelect' | 'renderResultContent' | 'results' | 'searchResultsTabBar'
>;

const MobileSearch = <T extends ResultBase>({
  iconButtonProps,
  focusTrapOptions,
  onCancel,
  onChange,
  onKeyDown,
  placeholder,
  query = '',
  testId,
  ...customSearchResultProps
}: MobileSearchProps<T>) => {
  useScreenLock(true);
  return (
    <ModalPortal>
      <FocusTrap
        active
        // fallbackFocus is used to prevent errors in testing https://github.com/focus-trap/focus-trap-react/issues/91
        focusTrapOptions={{
          fallbackFocus: '#mobile-search',
          initialFocus: false,
          returnFocusOnDeactivate: false, // Don't want to return focus to the search input on mobile because that keeps the keyboard open when the search modal closes
          ...focusTrapOptions
        }}
      >
        <div className={styles.container}>
          <div className={styles.inputContainer} id="mobile-search">
            <SearchInput
              autoFocus
              iconButtonProps={iconButtonProps}
              onChange={onChange}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              testId={testId}
              value={query}
            />
            <Link className={styles.cancel} onClick={onCancel} noUnderline>
              <FormattedMessage defaultMessage="Cancel" />
            </Link>
          </div>
          <CustomSearchResults {...customSearchResultProps} className={styles.results} query={query} />
        </div>
      </FocusTrap>
    </ModalPortal>
  );
};

export default MobileSearch;
