import { useEffect } from 'react';
import useUser from '@alltrails/context/hooks/useUser';
import { appleRedirectHandler } from '../utils/OAuthHandlers';

const useAppleRedirectHandler = () => {
  const user = useUser();

  useEffect(() => {
    if (user) {
      appleRedirectHandler(user);
    }
  }, [user]);
};

export default useAppleRedirectHandler;
