import { useEffect, useRef, useState, useCallback, KeyboardEventHandler } from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import ChevronSm from '../../icons/ChevronSm';
import Tab, { TabDefinition } from './Tab';
import styles from './styles/styles.module.scss';

const toPx = (px: number) => `${px}px`;

const scrollOptions: ScrollIntoViewOptions = { behavior: 'smooth', block: 'nearest', inline: 'nearest' };

type Id = TabDefinition['id'];

type TabBarProps = {
  activeTab: Id;
  alignment?: 'start' | 'full';
  className?: string;
  hideBottomBorder?: boolean;
  indicatorPosition?: 'top' | 'bottom';
  onChange?: (id: Id) => void;
  tabs: TabDefinition[];
  testId: string;
};

const TabBar = ({
  activeTab,
  alignment = 'start',
  className,
  hideBottomBorder,
  indicatorPosition = 'bottom',
  onChange,
  tabs,
  testId
}: TabBarProps): JSX.Element => {
  const tabRefs = useRef<{ [id: Id]: HTMLElement }>({});
  const activeTabIndicator = useRef<HTMLDivElement>(null);
  const [activeTabElement, setActiveTabElement] = useState<HTMLElement>();
  const tabBar = useRef<HTMLDivElement>(null);
  const leftArrow = useRef<HTMLButtonElement>(null);
  const rightArrow = useRef<HTMLButtonElement>(null);

  const positionActiveTabIndicator = useCallback(() => {
    if (activeTabElement && activeTabIndicator.current) {
      activeTabIndicator.current.style.opacity = '1';
      activeTabIndicator.current.style.width = toPx(activeTabElement.clientWidth);
      activeTabIndicator.current.style.left = toPx(activeTabElement.offsetLeft);

      // Apply the transition style after a short delay so that the initial positioning isn't animated
      if (!activeTabIndicator.current.style.transition) {
        setTimeout(() => {
          if (activeTabElement && activeTabIndicator.current) {
            activeTabIndicator.current.style.transition = 'all 0.4s ease-in-out';
          }
        }, 500);
      }
    } else if (!activeTabElement && activeTabIndicator.current) {
      activeTabIndicator.current.style.opacity = '0';
    }
  }, [activeTabElement]);

  // alignment and tabs should really only change in storybook
  useEffect(positionActiveTabIndicator, [positionActiveTabIndicator, alignment, tabs]);

  const updateArrowButtons = useCallback(() => {
    if (alignment === 'full' || !tabBar.current || !leftArrow.current || !rightArrow.current) {
      return;
    }
    const { clientWidth, scrollWidth, scrollLeft } = tabBar.current;
    if (scrollLeft < 1) {
      leftArrow.current.style.display = 'none';
    } else {
      leftArrow.current.style.display = 'flex';
    }

    if (scrollWidth - clientWidth - scrollLeft > 1) {
      rightArrow.current.style.display = 'flex';
    } else {
      rightArrow.current.style.display = 'none';
    }
  }, [alignment]);

  const handleResize = useCallback(() => {
    positionActiveTabIndicator();
    updateArrowButtons();
  }, [positionActiveTabIndicator, updateArrowButtons]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', debounce(handleResize, 300));

    return () => {
      window.removeEventListener('resize', debounce(handleResize, 300));
    };
  }, [handleResize]);

  // Scroll left or right by the width of the tab bar
  const onArrowClick = (right?: boolean) => {
    if (!tabBar.current) {
      return;
    }
    const direction = right ? 1 : -1;
    tabBar.current?.scrollBy({ behavior: 'smooth', left: direction * tabBar.current.clientWidth });
  };

  const switchToTab = (tab: TabDefinition) => {
    const tabElement = tabRefs.current[tab.id];
    if (tabElement) {
      tabElement.focus();
      tabElement.scrollIntoView(scrollOptions);
    }
    if (!tab.linkInfo) {
      // Switching to a tab should trigger the onChange, unless its a link because the user would have no way to navigate past that tab
      onChange?.(tab.id);
    }
  };

  const getTabOnKeyDown =
    (index: number): KeyboardEventHandler<HTMLElement> =>
    e => {
      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          e.preventDefault();
          if (index > 0) {
            switchToTab(tabs[index - 1]);
          }
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          e.preventDefault();
          if (index < tabs.length - 1) {
            switchToTab(tabs[index + 1]);
          }
          break;
        default:
        // do nothing
      }
    };

  const onTabClick = (id: Id) => {
    const tabElement = tabRefs.current[id];
    if (tabElement) {
      tabElement.scrollIntoView(scrollOptions);
    }
    onChange?.(id);
  };

  const renderedTabs = tabs.map((tab, index) => {
    const active = tab.id === activeTab;
    return (
      <Tab
        key={tab.id}
        {...tab}
        active={active}
        indicatorPosition={indicatorPosition}
        onClick={onTabClick}
        onKeyDown={getTabOnKeyDown(index)}
        tabRef={el => {
          if (active) {
            setActiveTabElement(el);
          }
          tabRefs.current[tab.id] = el;
        }}
      />
    );
  });

  return (
    <div className={classNames(className, styles.container, hideBottomBorder && styles.hideBottomBorder)}>
      <div
        className={classNames(styles.tabBar, styles[alignment])}
        onScroll={debounce(updateArrowButtons, 100)}
        ref={tabBar}
        role="tablist"
        data-testid={testId}
      >
        {renderedTabs}
        <div className={classNames(styles.activeTabIndicator, { [styles.topIndicator]: indicatorPosition === 'top' })} ref={activeTabIndicator} />
      </div>
      <button
        className={styles.leftArrow}
        onClick={() => onArrowClick()}
        ref={leftArrow}
        tabIndex={-1}
        aria-hidden
        type="button"
        data-testid={`${testId}_LeftArrow`}
      >
        <ChevronSm orientation="left" />
      </button>
      <button
        className={styles.rightArrow}
        onClick={() => onArrowClick(true)}
        ref={rightArrow}
        tabIndex={-1}
        aria-hidden
        type="button"
        data-testid={`${testId}_RightArrow`}
      >
        <ChevronSm orientation="right" />
      </button>
    </div>
  );
};

export default TabBar;
