import { useCallback, useRef } from 'react';
import type { ExperimentClient } from '@amplitude/experiment-js-client';
import Experiments from '@alltrails/core/types/Experiments';
import useExperiments from './useExperiments';

const useExposureEvent = (experimentKey: keyof Experiments) => {
  const experiments = useExperiments();
  const experimentClient = useRef<ExperimentClient>();

  const setupExperimentClient = useCallback(async () => {
    const amplitudeModule = await import('@amplitude/experiment-js-client');
    const { Experiment, Source } = amplitudeModule;
    experimentClient.current = Experiment.initializeWithAmplitudeAnalytics(process.env.NEXT_PUBLIC_AMPLITUDE_DEPLOYMENT_KEY, {
      fetchOnStart: false,
      initialVariants: experiments,
      source: Source.InitialVariants
    });
  }, [experiments]);

  const sendExposureEvent = useCallback(async () => {
    if (!experimentClient.current) {
      await setupExperimentClient();
    }

    if (experimentClient.current) {
      experimentClient.current.exposure(experimentKey);
    }
  }, [experimentKey, setupExperimentClient]);

  return sendExposureEvent;
};

export default useExposureEvent;
