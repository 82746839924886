import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import getHasLegacyPortalAccess from '@alltrails/context/utils/getHasLegacyPortalAccess';
import { userHasPermission } from '@alltrails/context/hooks/useAuthorization';
import { isSubscriber } from '@alltrails/context/utils/isSubscriber';
import type Context from '@alltrails/core/types/Context';
import type User from '@alltrails/core/types/User';
import PlaceTrail from '@alltrails/denali/icons/PlaceTrail';
import Community from '@alltrails/denali/icons/Community';
import NationalPark from '@alltrails/denali/icons/NationalPark';
import { DropdownMenuOption, DropdownMenuTextOption, LinkInfo } from '@alltrails/denali/types';
import PlusBadge from '@alltrails/denali/components/PlusBadge';
import Heart from '@alltrails/denali/icons/Heart';
import Shop from '@alltrails/denali/icons/Shop';
import ArrowExternal from '@alltrails/denali/icons/ArrowExternal';
import List from '@alltrails/denali/icons/List';
import Bookmark from '@alltrails/denali/icons/Bookmark';
import Settings from '@alltrails/denali/icons/Settings';
import Help from '@alltrails/denali/icons/Help';
import Logout from '@alltrails/denali/icons/Logout';
import Map from '@alltrails/denali/icons/Map';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import Chevron from '@alltrails/denali/icons/Chevron';
import DrawPath from '@alltrails/denali/icons/DrawPath';
import Avatar from '@alltrails/denali/components/Avatar';
import { fullName } from '@alltrails/shared/utils/formatters/textFormatters';
import WebHeaderSection from '@alltrails/analytics/enums/WebHeaderSection';
import WebHeaderDropDownLink from '@alltrails/analytics/enums/WebHeaderDropDownLink';
import logWebHeaderDropDownTapped from '@alltrails/analytics/events/logWebHeaderDropDownTapped';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import UpsellTrigger from '@alltrails/analytics/enums/UpsellTrigger';
import styles from './styles/styles.module.scss';

type HeaderLinkBase = Pick<DropdownMenuTextOption, 'className' | 'leadingIcon' | 'onClick' | 'subtext' | 'title' | 'testId' | 'trailingIcon'> & {
  webHeaderDropDownLink: WebHeaderDropDownLink;
};

export type AuthRequiredHeaderLink = HeaderLinkBase & {
  requiresAuth: true;
  getLinkInfo: (user: User) => LinkInfo;
  trigger: CarouselDisplayTrigger;
  upsellTrigger: UpsellTrigger;
};

export type BasicHeaderLink = HeaderLinkBase & { requiresAuth?: never; linkInfo: LinkInfo };

export type HeaderLink = AuthRequiredHeaderLink | BasicHeaderLink;

export type HeaderSection = {
  label: ReactNode;
  links: HeaderLink[];
  testId: string;
  webHeaderSection: WebHeaderSection;
};

export const getHeaderLinks = (
  user: User | null,
  languageRegionCode: LanguageRegionCode,
  inUS: boolean,
  hasByotExperiment: boolean
): HeaderSection[] => {
  const exploreSection: HeaderSection = {
    label: <FormattedMessage defaultMessage="Explore" />,
    links: [
      {
        leadingIcon: { background: true, icon: { Component: PlaceTrail } },
        linkInfo: { href: wrapUrlSafe('/explore?ref=header', languageRegionCode), outsideOfMugen: true },
        testId: 'header-nearby-trails',
        title: <FormattedMessage defaultMessage="Nearby trails" />,
        webHeaderDropDownLink: WebHeaderDropDownLink.NearbyTrails
      },
      {
        leadingIcon: { background: true, icon: { Component: Community } },
        linkInfo: { href: wrapUrlSafe('/community?ref=header', languageRegionCode) },
        testId: 'header-community',
        title: <FormattedMessage defaultMessage="Community" />,
        webHeaderDropDownLink: WebHeaderDropDownLink.Community
      },
      {
        leadingIcon: { background: true, icon: { Component: NationalPark } },
        linkInfo: { href: wrapUrlSafe('/guides?ref=header', languageRegionCode) },
        testId: 'header-national-park-guides',
        title: <FormattedMessage defaultMessage="National park guides" />,
        webHeaderDropDownLink: WebHeaderDropDownLink.NationalParkGuides
      }
    ],
    testId: 'header-explore',
    webHeaderSection: WebHeaderSection.Explore
  };

  const savedLinks: HeaderLink[] = user
    ? [
        {
          leadingIcon: { background: true, icon: { Component: Bookmark } },
          linkInfo: { href: wrapUrlSafe(`/members/${user.slug}/favorites?ref=header`, languageRegionCode) },
          testId: 'header-favorites',
          title: <FormattedMessage defaultMessage="Favorites" />,
          webHeaderDropDownLink: WebHeaderDropDownLink.Favorites
        },
        hasByotExperiment
          ? {
              leadingIcon: { background: true, icon: { Component: DrawPath } },
              linkInfo: { href: wrapUrlSafe(`/members/${user.slug}/custom-routes?ref=header`, languageRegionCode) },
              testId: 'header-custom-routes',
              title: <FormattedMessage defaultMessage="Custom routes" />,
              // TODO: https://alltrails.atlassian.net/browse/DISCO-13414
              webHeaderDropDownLink: WebHeaderDropDownLink.MyMaps
            }
          : {
              leadingIcon: { background: true, icon: { Component: Map } },
              linkInfo: { href: wrapUrlSafe(`/members/${user.slug}/maps?ref=header`, languageRegionCode) },
              testId: 'header-maps',
              title: <FormattedMessage defaultMessage="My maps" />,
              webHeaderDropDownLink: WebHeaderDropDownLink.MyMaps
            },
        {
          leadingIcon: { background: true, icon: { Component: List } },
          linkInfo: { href: wrapUrlSafe(`/members/${user.slug}/lists?ref=header`, languageRegionCode) },
          testId: 'header-lists',
          title: <FormattedMessage defaultMessage="Show all lists" />,
          webHeaderDropDownLink: WebHeaderDropDownLink.ViewAllLists
        }
      ]
    : [
        {
          leadingIcon: { background: true, icon: { Component: List } },
          testId: 'header-create-list',
          title: <FormattedMessage defaultMessage="Create a list" />,
          requiresAuth: true,
          getLinkInfo: ({ slug }) => ({
            href: wrapUrlSafe(`/members/${slug}/lists?ref=header`, languageRegionCode)
          }),
          trigger: CarouselDisplayTrigger.CreateList,
          upsellTrigger: UpsellTrigger.CreateList,
          webHeaderDropDownLink: WebHeaderDropDownLink.CreateAList
        },
        {
          leadingIcon: { background: true, icon: { Component: Map } },
          testId: 'header-create-map',
          title: <FormattedMessage defaultMessage="Create a map" />,
          requiresAuth: true,
          getLinkInfo: () => ({ href: wrapUrlSafe('/explore/map/new', languageRegionCode), outsideOfMugen: true }),
          trigger: CarouselDisplayTrigger.CreateMap,
          upsellTrigger: UpsellTrigger.CreateMap,
          webHeaderDropDownLink: WebHeaderDropDownLink.CreateAMap
        }
      ];
  const savedSection: HeaderSection = {
    label: <FormattedMessage defaultMessage="Saved" />,
    links: savedLinks,
    testId: 'header-saved',
    webHeaderSection: WebHeaderSection.Save
  };

  const shopLinks: HeaderLink[] = [];
  if (!isSubscriber(user)) {
    shopLinks.push({
      leadingIcon: { background: true, customElement: <PlusBadge size="md" /> },
      linkInfo: { href: wrapUrlSafe('/plus?ref=header', languageRegionCode) },
      testId: 'header-alltrails-plus',
      title: <FormattedMessage defaultMessage="AllTrails+" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.AtPlus
    });
  }
  shopLinks.push({
    leadingIcon: { background: true, icon: { Component: Heart } },
    linkInfo: { href: wrapUrlSafe('/gift?ref=header', languageRegionCode) },
    testId: 'header-give-alltrails-plus',
    title: <FormattedMessage defaultMessage="Give AllTrails+" />,
    webHeaderDropDownLink: WebHeaderDropDownLink.GiveAtPlus
  });
  if (inUS) {
    shopLinks.push({
      leadingIcon: { background: true, icon: { Component: Shop } },
      linkInfo: { href: 'https://shop.alltrails.com', outsideOfMugen: true },
      testId: 'header-shop',
      title: 'Gear Shop', // This is intentionally untranslated (DISCO-10275)
      trailingIcon: { icon: { Component: ArrowExternal, color: '--color-text-secondary', size: 'xs' } },
      webHeaderDropDownLink: WebHeaderDropDownLink.GearShop
    });
  }
  const shopSection: HeaderSection = {
    label: <FormattedMessage defaultMessage="Shop" />,
    links: shopLinks,
    testId: 'header-shop',
    webHeaderSection: WebHeaderSection.Shop
  };

  return [exploreSection, savedSection, shopSection];
};

export const getUserLinks = (user: User, languageRegionCode: LanguageRegionCode, includeLeadingIcons = false): HeaderLink[] => {
  const links: HeaderLink[] = [];
  const fullUserName = fullName(user);
  links.push({
    leadingIcon: {
      customElement: <Avatar hasPlus={isSubscriber(user)} size="sm" testId="header-show-profile-avatar" userId={user.id} userName={fullUserName} />
    },
    linkInfo: { href: wrapUrlSafe(`/members/${user.slug}?ref=header`, languageRegionCode) },
    subtext: <FormattedMessage defaultMessage="Show profile" />,
    testId: 'header-show-profile',
    title: fullUserName,
    trailingIcon: { icon: { Component: Chevron, orientation: 'right', size: 'sm' } },
    webHeaderDropDownLink: WebHeaderDropDownLink.ShowProfile
  });

  if (!isSubscriber(user)) {
    links.push({
      className: styles.plusBackground,
      linkInfo: { href: wrapUrlSafe('/plus?ref=header', languageRegionCode) },
      testId: 'header-try-alltrails-plus',
      title: <FormattedMessage defaultMessage="Try AllTrails+ for free" />,
      trailingIcon: { customElement: <PlusBadge size="md" /> },
      webHeaderDropDownLink: WebHeaderDropDownLink.AtPlus
    });
  }

  links.push(
    {
      leadingIcon: includeLeadingIcons ? { icon: { Component: Settings }, background: true } : undefined,
      linkInfo: { href: wrapUrlSafe('/my/profile/edit?ref=header', languageRegionCode), outsideOfMugen: true },
      testId: 'header-settings',
      title: <FormattedMessage defaultMessage="Settings" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.Settings
    },
    {
      leadingIcon: includeLeadingIcons ? { icon: { Component: Help }, background: true } : undefined,
      linkInfo: { href: getHelpCenterUrl(languageRegionCode), outsideOfMugen: true },
      testId: 'header-help-center',
      title: <FormattedMessage defaultMessage="Help center" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.HelpCenter
    },
    {
      leadingIcon: includeLeadingIcons ? { icon: { Component: Logout }, background: true } : undefined,
      // The sign out link must NOT prefetch - if it does, the user is signed out upon opening the header dropdown
      linkInfo: { href: wrapUrlSafe('/sign_out?ref=header', languageRegionCode), outsideOfMugen: true, prefetch: false },
      testId: 'header-log-out',
      onClick: () => {
        window.Sprig?.('logoutUser');
      },
      title: <FormattedMessage defaultMessage="Log out" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.CreateAMap
    }
  );

  return links;
};

export const getUserPermissionLinks = (user: User, languageRegionCode: LanguageRegionCode, context: Context): DropdownMenuOption[] => {
  const adminHeaderSection: DropdownMenuOption = {
    sectionHeader: <FormattedMessage defaultMessage="Admin" />
  };

  const partnersHeaderSection: DropdownMenuOption = {
    sectionHeader: <FormattedMessage defaultMessage="Partners" />
  };

  const internalToolsLink: DropdownMenuOption = {
    linkInfo: { href: wrapUrlSafe('/manage/search', languageRegionCode) },
    testId: 'header-internal-tools',
    title: <FormattedMessage defaultMessage="Internal tools" />,
    trailingIcon: { icon: { Component: ArrowExternal, size: 'sm' } }
  };

  const publicLandsPortalLink: DropdownMenuOption = {
    linkInfo: { href: wrapUrlSafe('/portal', languageRegionCode) },
    testId: 'header-public-lands-portal',
    title: <FormattedMessage defaultMessage="Public Lands portal" />,
    trailingIcon: { icon: { Component: ArrowExternal, size: 'sm' } }
  };

  // Until the public_lands:manage role is launched, we need to use the legacy check to see if a user has portal access.
  // Since this is just a temporary patch, just cast the Context type here.
  const hasLegacyPortalAccess = getHasLegacyPortalAccess(context);

  // In the future we may want 'portal:view' or a similar permission that we assign to certain roles
  if (
    userHasPermission(user, 'areas:manage') ||
    userHasPermission(user, 'locations:manage') ||
    userHasPermission(user, 'public_lands_onboarding:manage')
  ) {
    return [adminHeaderSection, internalToolsLink, publicLandsPortalLink];
  } else if (userHasPermission(user, 'guides:manage') || userHasPermission(user, 'seo_tests:manage')) {
    return [adminHeaderSection, internalToolsLink];
  } else if (userHasPermission(user, 'public_lands:manage', hasLegacyPortalAccess)) {
    return [partnersHeaderSection, publicLandsPortalLink];
  }

  return [];
};

export const getHeaderLinkOnClickWithAnalytics = (
  index: number,
  webHeaderDropDownLink: WebHeaderDropDownLink,
  webHeaderSection: WebHeaderSection,
  onClick?: () => void
) => {
  return () => {
    logWebHeaderDropDownTapped({ item_name: webHeaderDropDownLink, item_position: index, web_header_section: webHeaderSection });
    onClick?.();
  };
};
