import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingDots from '@alltrails/denali/components/LoadingDots';
import TabBar from '@alltrails/denali/components/TabBar';
import classNames from 'classnames';
import { OnSelect, RenderResultContent, ResultBase, Results, SearchResultsEmptyState, SearchResultsTabBar } from '../../../types/searchBoxTypes';
import SectionHeader from './SectionHeader';
import ResultItem from './ResultItem';
import styles from './styles/CustomSearchResults.module.scss';

const EmptyState = ({ primary, secondary }: SearchResultsEmptyState) => {
  return (
    <div className={styles.emptyState}>
      <div className={styles.primary}>{primary}</div>
      <div className={styles.secondary}>{secondary}</div>
    </div>
  );
};

type CustomSearchResultsProps<T extends ResultBase> = {
  activeId?: string;
  areResultsLoading?: boolean;
  className?: string;
  emptyState?: SearchResultsEmptyState;
  onSelect: OnSelect<T>;
  preventScrollOnResultSelection?: boolean;
  query: string;
  results: Results<T>;
  renderResultContent: RenderResultContent<T>;
  searchResultsTabBar?: SearchResultsTabBar;
};

const CustomSearchResults = <T extends ResultBase>({
  activeId,
  areResultsLoading,
  className,
  emptyState,
  onSelect,
  preventScrollOnResultSelection,
  query,
  results,
  renderResultContent,
  searchResultsTabBar
}: CustomSearchResultsProps<T>) => {
  const tabBar = useMemo(() => {
    if (!searchResultsTabBar) {
      return null;
    }
    return <TabBar {...searchResultsTabBar} className={styles.tabBar} testId="search-tabs" />;
  }, [searchResultsTabBar]);

  const content = useMemo(() => {
    if (results.length === 0 && !query) {
      if (emptyState) {
        return <EmptyState {...emptyState} />;
      }
      return null;
    }
    if (results.length) {
      return results.map((result, index) => {
        if ('text' in result) {
          return <SectionHeader key={result.text} heading={result} />;
        }
        return (
          <ResultItem
            className={result.containerClassName}
            key={result.id}
            isActive={activeId === result.id}
            onClick={() => onSelect(result as T, { index, query })}
            preventAutoScroll={preventScrollOnResultSelection}
          >
            {renderResultContent(result as T)}
          </ResultItem>
        );
      });
    }
    if (areResultsLoading) {
      return (
        <div className={styles.loadingState}>
          <LoadingDots testId="search-loading" />
        </div>
      );
    }
    return (
      <EmptyState
        primary={<FormattedMessage defaultMessage="No results" />}
        secondary={<FormattedMessage defaultMessage="We couldn't find anything matching {query}" values={{ query }} />}
      />
    );
  }, [activeId, areResultsLoading, emptyState, onSelect, preventScrollOnResultSelection, query, renderResultContent, results]);

  return tabBar || content ? (
    // prevent blurring the input when clicking inside the dropdown
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={classNames(styles.container, className)} onMouseDown={e => e.preventDefault()}>
      {tabBar}
      {content ? <div className={styles.scrollable}>{content}</div> : null}
    </div>
  ) : null;
};

export default CustomSearchResults;
