'use client';

import { useState } from 'react';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import useUser from '@alltrails/context/hooks/useUser';
import type Context from '@alltrails/core/types/Context';
import { isSubscriber } from '@alltrails/context/utils/isSubscriber';
import DropdownMenu from '@alltrails/denali/components/DropdownMenu';
import Button from '@alltrails/denali/components/Button';
import { FormattedMessage } from 'react-intl';
import Avatar from '@alltrails/denali/components/Avatar';
import QrCode from '@alltrails/denali/icons/QrCode';
import { fullName } from '@alltrails/shared/utils/formatters/textFormatters';
import InstallModal from '@alltrails/install-prompt/components/InstallModal';
import { DropdownMenuOption } from '@alltrails/denali/types';
import { useSelector } from 'react-redux';
import logWebHeaderHovered from '@alltrails/analytics/events/logWebHeaderHovered';
import logWebHeaderLoginButtonTapped from '@alltrails/analytics/events/logWebHeaderLoginButtonTapped';
import WebHeaderSection from '@alltrails/analytics/enums/WebHeaderSection';
import TrailDetailsActionLocation from '@alltrails/analytics/enums/TrailDetailsActionLocation';
import styles from './styles/styles.module.scss';
import { HeaderLink, getHeaderLinkOnClickWithAnalytics, getUserLinks, getUserPermissionLinks } from './headerLinks';
import { HeaderBackground } from './types';

type UserCTAProps = {
  background: HeaderBackground;
};

const UserCTA = ({ background }: UserCTAProps) => {
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const context = useSelector((state: { context: Context }) => state.context);

  const [isOpen, setIsOpen] = useState(false);
  const [showInstallModal, setShowInstallModal] = useState(false);

  if (!user) {
    return (
      <Button
        linkInfo={{ href: '/login', outsideOfMugen: true }}
        onClick={() => {
          logWebHeaderLoginButtonTapped();
        }}
        size="sm"
        text={<FormattedMessage defaultMessage="Log in" />}
        testId="header-log-in"
        variant={background === 'transparent-inverted' ? 'inverse' : 'primary'}
      />
    );
  }

  const openDropdown = () => {
    logWebHeaderHovered({ web_header_position: 3, web_header_section: WebHeaderSection.MyAccount });
    setIsOpen(true);
  };

  // Wrapping div around the Avatar is needed for the DropdownMenu to set a ref
  const anchor = (
    <div>
      <Avatar hasPlus={isSubscriber(user)} onClick={openDropdown} size="sm" testId="header-avatar" userId={user.id} userName={fullName(user)} />
    </div>
  );

  const options: DropdownMenuOption[] = getUserLinks(user, languageRegionCode);
  if (isSubscriber(user)) {
    options.splice(
      1,
      0,
      { sectionHeader: null },
      {
        onClick: () => {
          setIsOpen(false);
          setShowInstallModal(true);
        },
        testId: 'header-continue-in-app',
        title: <FormattedMessage defaultMessage="Continue in app" />,
        trailingIcon: { icon: { Component: QrCode, size: 'sm' } }
      },
      { sectionHeader: null }
    );
  }
  options.push(...getUserPermissionLinks(user, languageRegionCode, context));

  const optionsWithAnalytics = options.map((option: HeaderLink | DropdownMenuOption, index) => {
    if ('webHeaderDropDownLink' in option) {
      const onClick = getHeaderLinkOnClickWithAnalytics(index, option.webHeaderDropDownLink, WebHeaderSection.MyAccount, option.onClick);
      return { ...option, onClick };
    }
    return option;
  });

  return (
    <>
      <DropdownMenu
        anchor={anchor}
        className={styles.dropdownMenu}
        containerMouseHandlers={{ onMouseEnter: openDropdown, onMouseLeave: () => setIsOpen(false) }}
        isOpen={isOpen}
        onCloseRequest={() => setIsOpen(false)}
        options={optionsWithAnalytics}
        testId="header-avatar-dropdown"
      />
      <InstallModal
        isOpen={showInstallModal}
        onCloseRequest={() => setShowInstallModal(false)}
        location={TrailDetailsActionLocation.GlobalNavProfileDropdown}
      />
    </>
  );
};

export default UserCTA;
