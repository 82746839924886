import { useEffect } from 'react';
import { trackTinuitiPageView, handleTinuitiCookie } from '@alltrails/shared/utils/tinuitiUtils';
import useUser from '@alltrails/context/hooks/useUser';

const useTinuitiPixel = () => {
  const user = useUser();
  const userId = user?.id?.toString();

  useEffect(() => {
    handleTinuitiCookie();
    trackTinuitiPageView(userId);
  }, [userId]);
};

export default useTinuitiPixel;
