'use client';

import { useState } from 'react';
import { defineMessages } from 'react-intl';
import Select from '@alltrails/denali/components/Select';
import { useFormatMessage } from '@alltrails/language/hooks/useFormatMessage';
import { SUPPORTED_LANGUAGES } from '@alltrails/shared/utils/constants/languageRegionCodes';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import useLanguageChangeHandler from '../../../hooks/useLanguageChangeHandler';

const messages = defineMessages({
  LABEL: { defaultMessage: 'Select a language' }
});

type LanguageSelectDropdownProps = {
  languageRegionCode: LanguageRegionCode;
  enabledLanguages?: Array<string>;
  pageReloadOnLanguageChange?: boolean;
};

const defaultEnabledLanguages = [LanguageRegionCode.German, LanguageRegionCode.English, LanguageRegionCode.Spanish, LanguageRegionCode.French];

const LanguageSelectDropdown = ({
  languageRegionCode,
  enabledLanguages = defaultEnabledLanguages,
  pageReloadOnLanguageChange
}: LanguageSelectDropdownProps): JSX.Element => {
  const [selectedLanguage, setSelectedLanguage] = useState(languageRegionCode);
  const onLanguageChange = useLanguageChangeHandler(pageReloadOnLanguageChange);

  const {
    formattedDefaultMessages: { LABEL }
  } = useFormatMessage(messages);

  const availableLanguages = SUPPORTED_LANGUAGES.filter(l => enabledLanguages.includes(l.languageRegionCode));

  const options = availableLanguages.map(l => ({
    label: l.translatedLanguageName,
    value: l.languageRegionCode,
    dataTestId: l.translatedLanguageName
  }));

  const handleSelectChangeNew = (newLanguageRegionCode: string) => {
    if (selectedLanguage === newLanguageRegionCode) {
      return;
    }

    setSelectedLanguage(newLanguageRegionCode as LanguageRegionCode);
    onLanguageChange(newLanguageRegionCode as LanguageRegionCode);
  };

  return (
    <div id="language-button">
      <Select
        options={options}
        value={selectedLanguage}
        onChange={handleSelectChangeNew}
        labelText={LABEL}
        placeholder={LABEL}
        testId="language-dropdown"
        size="sm"
        hideLabel
      />
    </div>
  );
};

export default LanguageSelectDropdown;
