import { useIntl } from 'react-intl';
import { Image } from '@alltrails/core';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';

type GoogleLaurelProps = { height: number };

const GoogleLaurel = ({ height }: GoogleLaurelProps) => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();

  const src = require(`./laurels/google-laurel-${languageRegionCode}.svg`);

  return <Image alt={intl.formatMessage({ defaultMessage: 'Google Play Editors’ Choice' })} src={src} height={height} />;
};

export default GoogleLaurel;
