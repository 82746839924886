import { FormattedMessage } from 'react-intl';
import { ComponentProps, useEffect } from 'react';
import { Modal } from '@alltrails/modal';
import { Typography } from '@alltrails/core';
import logQRModalShown from '@alltrails/analytics/events/logQRModalShown';
import TrailDetailsActionLocation from '@alltrails/analytics/enums/TrailDetailsActionLocation';
import AppleLaurel from '../AppleLaurel';
import GoogleLaurel from '../GoogleLaurel';
import AppSalesPitch from '../AppSalesPitch';
import AppStarRating from '../AppStarRating';
import QRCode from '../QRCode';
import styles from './styles/styles.module.scss';

type InstallModalProps = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onCloseRequest'> & {
  location?: TrailDetailsActionLocation;
};

const InstallModal = ({ isOpen, onCloseRequest, location }: InstallModalProps) => {
  useEffect(() => {
    if (isOpen && location) {
      logQRModalShown({ module_location: location });
    }
  }, [isOpen, location]);

  return (
    <Modal isOpen={isOpen} onCloseRequest={onCloseRequest} title={<FormattedMessage defaultMessage="Get the app" />} titleClassName={styles.title}>
      <div className={styles.container}>
        <div className={styles.laurels}>
          <AppleLaurel height={32} />
          <GoogleLaurel height={32} />
        </div>
        <Typography className={styles.salesPitch} color="secondary" variant="text100">
          <AppSalesPitch />
        </Typography>
        <AppStarRating size="sm" />
        <QRCode size={180} />
      </div>
    </Modal>
  );
};

export default InstallModal;
