import { useMemo } from 'react';
import User from '@alltrails/core/types/User';
import type Permission from '../types/Permission';
import useUser from './useUser';
import useHasLegacyPortalAccess from './useHasLegacyPortalAccess';

export const userHasPermission = (user: User | null, permission: Permission, hasLegacyPortalAccess?: boolean) => {
  if (user?.permissions?.includes(permission)) {
    return true;
  }

  // Fall back to legacy portal access check until public_lands:manage is fully implemented.
  if (permission === 'public_lands:manage' && hasLegacyPortalAccess) {
    return true;
  }

  // Fall back to legacy admin check.
  return Boolean(user?.admin);
};

type HasPermissionArgs = {
  permission: Permission;
};

export default function useAuthorization() {
  const user = useUser();
  const hasLegacyPortalAccess = useHasLegacyPortalAccess();

  return useMemo(() => {
    return {
      hasPermission: ({ permission }: HasPermissionArgs) => {
        return userHasPermission(user, permission, hasLegacyPortalAccess);
      }
    };
  }, [user, hasLegacyPortalAccess]);
}
