'use client';

import useOsano from '@alltrails/layout/hooks/useOsano';
import useBraze from '@alltrails/layout/hooks/useBraze';
import { startDatadogRum } from '@alltrails/stability';
import useTinuitiPixel from '@alltrails/layout/hooks/useTinuitiPixel';
import AmplitudeBrowserSdk from '@alltrails/layout/scripts/AmplitudeBrowserSdk';
import GoogleOneTap from '@alltrails/layout/components/GoogleOneTap';
import ReturningUserUpsell from '@alltrails/upsell/components/ReturningUserUpsell/ReturningUserUpsell';
import { useAppleRedirectHandler } from '@alltrails/user-auth';

startDatadogRum();

const LocationPageAnalytics = () => {
  useOsano();
  useTinuitiPixel();
  useBraze();
  useAppleRedirectHandler();

  return (
    <>
      <AmplitudeBrowserSdk />
      <GoogleOneTap pageViewLimitReached={false} />
      <ReturningUserUpsell />
    </>
  );
};

export default LocationPageAnalytics;
