'use client';

import { ComponentProps } from 'react';
import AlgoliaSearchBox from '@alltrails/search/components/AlgoliaSearchBox';
import { useIntl } from 'react-intl';
import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import styles from './styles/styles.module.scss';

export type SearchBoxProps = Pick<
  ComponentProps<typeof AlgoliaSearchBox>,
  | 'clearOnSelect'
  | 'configs'
  | 'getCurrentLatLng'
  | 'navigateOnSelect'
  | 'onAddressSelect'
  | 'onInputBlur'
  | 'onInputFocus'
  | 'onResultSelect'
  | 'tertiaryElement'
  | 'value'
>;

const SearchBox = (searchProps: SearchBoxProps) => {
  const intl = useIntl();

  return (
    <div className={styles.searchBox}>
      <AlgoliaSearchBox
        {...searchProps}
        dropdownVariant="enveloping"
        placeholder={intl.formatMessage({ defaultMessage: 'Search' })}
        searchOrigin={SearchOrigin.GlobalNav}
        searchTypes="all"
        testId="header-search"
        variant="subtle"
      />
    </div>
  );
};
export default SearchBox;
