'use client';

import Script from 'next/script';
import { useEffect, useState } from 'react';

const SPRIG_ENVIRONMENT_ID = process.env.NEXT_PUBLIC_SPRIG_ENVIRONMENT_ID;

type Props = {
  userId?: number;
};

export default function Sprig({ userId }: Props) {
  const [isConsentGranted, setConsentGranted] = useState(false);

  // useEffect so this only runs client-side. Otherwise the build phase will complain about window being undefined.
  useEffect(() => {
    setConsentGranted(Boolean(window.Osano?.cm?.analytics));
  }, []);

  useEffect(() => {
    if (!isConsentGranted) {
      return;
    }

    if (userId) {
      // We can invoke this function safely multiple times. Call it if a user logs in client-side, like via a CTA that triggers a log in modal.
      // https://docs.sprig.com/docs/web-javascript#set-user-id
      window.Sprig?.('setUserId', String(userId));
    }
  }, [isConsentGranted, userId]);

  if (!isConsentGranted) {
    return null;
  }

  return (
    <Script id="sprig-script">
      {`
      (function(l,e,a,p) {
      if (window.Sprig) return;
      window.Sprig = function(){S._queue.push(arguments)}
      var S = window.Sprig;S.appId = a;S._queue = [];window.UserLeap=S;
      a=l.createElement('script');
      a.async=1;a.src=e+'?id='+S.appId;
      p=l.getElementsByTagName('script')[0];
      p.parentNode.insertBefore(a, p);
      })(document, 'https://cdn.sprig.com/shim.js', '${SPRIG_ENVIRONMENT_ID}');

      // Set the user id on initial load.
      if (Boolean(${userId})) {
        Sprig('setUserId', "${userId}");
      }
      `}
    </Script>
  );
}
