import { datadogRum } from '@datadog/browser-rum';

const bugsnagEnabled = process.env.NEXT_PUBLIC_BUGSNAG_ENABLED;
const apiKey = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
const namespace = process.env.NEXT_PUBLIC_DEPLOY_NAMESPACE;
const gitHash = process.env.NEXT_PUBLIC_GIT_COMMIT;
const nextPhase = process.env.NEXT_PHASE;
const appName = process.env.NEXT_PUBLIC_APP_NAME;

type Params = {
  allowedTracingUrls?: string[];
  sessionSampleRate?: number;
  sessionReplaySampleRate?: number;
  trackUserInteractions?: boolean;
  trackResources?: boolean;
  trackLongTasks?: boolean;
  viewName?: string;
};

export function startDatadogRum({
  allowedTracingUrls = ['https://alltrails.com', 'https://alpha.mostpaths.com', 'https://www.alltrails.com'],
  sessionSampleRate = 1,
  sessionReplaySampleRate = 1,
  trackUserInteractions = true,
  trackResources = true,
  trackLongTasks = true,
  viewName = undefined // Defaults to the page URL path
}: Params = {}) {
  if (typeof window !== 'undefined' && bugsnagEnabled && nextPhase !== 'phase-production-build') {
    const consent = window.Osano?.cm?.analytics ? 'granted' : 'not-granted';

    datadogRum.init({
      applicationId: '6c037657-ef60-4d06-bc58-183c4bf74819',
      clientToken: apiKey!,
      site: 'datadoghq.com',
      service: `mugen-${appName}`,
      env: namespace,
      version: gitHash,
      trackingConsent: consent,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls,
      sessionSampleRate,
      sessionReplaySampleRate,
      trackUserInteractions,
      trackResources,
      trackLongTasks,
      trackViewsManually: !!viewName
    });

    if (viewName) {
      datadogRum.startView({ name: viewName });
    }
  }

  return {};
}

export default startDatadogRum;
