'use client';

import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import Accordion from '@alltrails/denali/components/Accordion';
import IconButton from '@alltrails/denali/components/IconButton';
import Logo from '@alltrails/denali/components/Logo';
import Menu from '@alltrails/denali/icons/Menu';
import Close from '@alltrails/denali/icons/Close';
import Mobile from '@alltrails/denali/icons/Mobile';
import { DropdownMenuItem } from '@alltrails/denali/components/DropdownMenu';
import useDeeplink from '@alltrails/install-prompt/hooks/useDeeplink';
import Button from '@alltrails/denali/components/Button';
import useUser from '@alltrails/context/hooks/useUser';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import { ModalPortal, useAnimateInOutComponent, useScreenLock } from '@alltrails/core';
import WebHeaderSection from '@alltrails/analytics/enums/WebHeaderSection';
import logWebHeaderLoginButtonTapped from '@alltrails/analytics/events/logWebHeaderLoginButtonTapped';
import styles from './styles/styles.module.scss';
import useHeaderLinks from './useHeaderLinks';
import { getHeaderLinkOnClickWithAnalytics, getUserLinks } from './headerLinks';

const MobileMenu = () => {
  const intl = useIntl();
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();

  const [isOpen, setIsOpen] = useState(false);
  const getDeeplink = useDeeplink({ isOpen });

  const { isAnimating, isVisible, onAnimationEnd } = useAnimateInOutComponent(isOpen);

  useScreenLock(isVisible);

  const { headerLinks, getLinkInteractivity, signUpModal } = useHeaderLinks();

  const sections = headerLinks.slice(0);

  if (user) {
    const userLinks = getUserLinks(user, languageRegionCode, true);
    sections.push({
      label: <FormattedMessage defaultMessage="Account" />,
      links: userLinks,
      testId: 'header-account',
      webHeaderSection: WebHeaderSection.MyAccount
    });
  }

  return (
    <>
      <IconButton
        className={styles.mobileIconButton}
        icon={{ Component: Menu }}
        onClick={() => setIsOpen(true)}
        testId="mobile-header-menu"
        title={intl.formatMessage({ defaultMessage: 'Navigation Menu' })}
        variant="flat"
      />
      {(isAnimating || isVisible) && (
        <ModalPortal>
          <div
            className={classNames(styles.mobileDrawerBackground, {
              [styles.isAnimating]: isAnimating,
              [styles.isVisible]: isVisible
            })}
            onAnimationEnd={onAnimationEnd}
          >
            <FocusTrap
              active
              focusTrapOptions={{
                clickOutsideDeactivates: true,
                escapeDeactivates: true,
                onDeactivate: () => setIsOpen(false),
                fallbackFocus: '#mobile-header-drawer'
              }}
            >
              <div
                className={classNames(styles.mobileDrawer, {
                  [styles.isAnimating]: isAnimating,
                  [styles.isVisible]: isVisible
                })}
                id="mobile-header-drawer"
              >
                <div>
                  <div className={styles.drawerHeader}>
                    <Logo color="dark" size="sm" variant="logomark" />
                    <IconButton
                      className={styles.mobileIconButton}
                      icon={{ Component: Close }}
                      onClick={() => setIsOpen(false)}
                      testId="mobile-header-menu-close"
                      title={intl.formatMessage({ defaultMessage: 'Close' })}
                      variant="flat"
                    />
                  </div>
                  {sections.map((section, index) => {
                    return (
                      <Accordion
                        key={section.testId}
                        borderBottomContainerClassName={index === sections.length - 1 ? styles.accordionNoBorder : undefined}
                        headingTypography="heading400"
                        initiallyOpen={index === 0}
                        title={section.label}
                        variant="minimal"
                      >
                        <div className={styles.accordionContents} data-testid={`${section.testId}-contents`}>
                          {section.links.map((link, linkIndex) => {
                            const { linkInfo, onClick } = getLinkInteractivity(link);
                            const onClickWithAnalytics = getHeaderLinkOnClickWithAnalytics(
                              linkIndex,
                              link.webHeaderDropDownLink,
                              section.webHeaderSection,
                              () => {
                                setIsOpen(false);
                                onClick?.();
                              }
                            );
                            return (
                              <DropdownMenuItem
                                key={link.testId}
                                option={{
                                  className: classNames(styles.mobileMenuItem, link.className),
                                  leadingIcon: link.leadingIcon,
                                  linkInfo,
                                  onClick: onClickWithAnalytics,
                                  subtext: link.subtext,
                                  testId: link.testId,
                                  title: link.title,
                                  trailingIcon: link.trailingIcon
                                }}
                                textVariant="text200"
                              />
                            );
                          })}
                        </div>
                      </Accordion>
                    );
                  })}
                </div>
                <div className={styles.drawerCTAs}>
                  {user ? null : (
                    <>
                      <Button
                        linkInfo={{ href: '/signup', outsideOfMugen: true }}
                        text={<FormattedMessage defaultMessage="Sign up" />}
                        testId="header-sign-up"
                        variant="default"
                        width="full"
                      />
                      <Button
                        linkInfo={{ href: '/login', outsideOfMugen: true }}
                        onClick={() => {
                          logWebHeaderLoginButtonTapped();
                        }}
                        text={<FormattedMessage defaultMessage="Log in" />}
                        testId="header-log-in"
                        variant="default"
                        width="full"
                      />
                    </>
                  )}
                  <Button
                    icon={{ Component: Mobile }}
                    linkInfo={{ href: getDeeplink(), outsideOfMugen: true }}
                    testId="header-continue-in-app"
                    text={<FormattedMessage defaultMessage="Continue in app" />}
                    variant={user ? 'default' : 'primary'}
                    width="full"
                  />
                </div>
              </div>
            </FocusTrap>
          </div>
        </ModalPortal>
      )}
      {signUpModal}
    </>
  );
};

export default MobileMenu;
