import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { SearchType } from '@alltrails/shared/types/algoliaSearch';
import { defineMessages, type IntlShape } from 'react-intl';
import {
  AlgoliaHit,
  AlgoliaSearchResult,
  AreaSearchResult,
  GuideMetadata,
  GuideSearchResult,
  MapSearchResult,
  POISearchResult,
  PlaceSearchResult,
  TrackSearchResult,
  TrailSearchResult
} from '../types/algoliaResultTypes';

const messages = defineMessages({
  guide: { defaultMessage: 'Guide' },
  park: { defaultMessage: 'Park' },
  nationalPark: { defaultMessage: 'National Park' },
  list: { defaultMessage: 'List' },
  map: { defaultMessage: 'Map' },
  place: { defaultMessage: 'Place' },
  peak: { defaultMessage: 'Peak' },
  waterfall: { defaultMessage: 'Waterfall' },
  poi: { defaultMessage: 'Point of interest' },
  activity: { defaultMessage: 'Activity' },
  trail: { defaultMessage: 'Trail' }
});

export const getResultTypeStr = (result: AlgoliaSearchResult, intl: IntlShape) => {
  switch (result.type) {
    case 'area':
      return result.area_type === 'N' ? intl.formatMessage(messages.nationalPark) : intl.formatMessage(messages.park);
    case 'guide':
      return intl.formatMessage(messages.guide);
    case 'list':
      return intl.formatMessage(messages.list);
    case 'map':
      return intl.formatMessage(messages.map);
    case 'page':
      if (result.subtype === 'guides') {
        return intl.formatMessage(messages.guide);
      }
      return undefined;
    case 'place':
      return intl.formatMessage(messages.place);
    case 'poi':
      switch (result.subtype) {
        case 'peak':
          return intl.formatMessage(messages.peak);
        case 'waterfall':
          return intl.formatMessage(messages.waterfall);
        default:
          return intl.formatMessage(messages.poi);
      }
    case 'track':
      return intl.formatMessage(messages.activity);
    case 'trail':
      return intl.formatMessage(messages.trail);
    case 'filter':
    default:
      return undefined;
  }
};

export const algoliaHitToResult = (hit: AlgoliaHit, searchType?: SearchType): AlgoliaSearchResult => ({
  ...hit,
  id: hit.objectID,
  searchType
});

export const getLocation = (
  result: AreaSearchResult | MapSearchResult | PlaceSearchResult | POISearchResult | TrackSearchResult | TrailSearchResult,
  hideResultType?: boolean
) => [hideResultType ? null : result.type_label, result.location_label].filter(str => !!str).join(' • ');
export const getMapTrackLocation = (result: MapSearchResult | TrackSearchResult) =>
  [result.city_name, result.state_name, result.country_name].filter(place => !!place).join(', ');

export const getUrl = (result: AlgoliaSearchResult, languageRegionCode: LanguageRegionCode, useNewRouting: boolean) => {
  switch (result.type) {
    case 'filter':
      return wrapUrlSafe(`/explore?${result.slug}`, languageRegionCode);
    case 'list':
      return wrapUrlSafe(`/lists/${result.slug}`, languageRegionCode);
    case 'map':
      return wrapUrlSafe(`/explore/map/${result.slug}`, languageRegionCode);
    case 'track':
      return wrapUrlSafe(`/explore/recording/${result.slug}`, languageRegionCode);
    case 'area':
    case 'place':
      if (useNewRouting) {
        if (!result.slug) {
          return '';
        }
        const url = result.slug.startsWith('/') ? `/explore${result.slug}` : `/explore/${result.slug}`;
        return wrapUrlSafe(url, languageRegionCode);
      }
    case 'guide':
    case 'page':
    case 'poi':
    case 'trail':
    default: {
      if (!result.slug) {
        return '';
      }
      const url = result.slug.startsWith('/') ? result.slug : `/${result.slug}`;
      return wrapUrlSafe(url, languageRegionCode);
    }
  }
};

export const guideMetadataToSearchResult = (guide: GuideMetadata, searchType?: SearchType): GuideSearchResult => ({
  type: 'guide',
  id: guide.objectID,
  searchType,
  ...guide
});
