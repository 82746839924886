import { KeyboardEventHandler } from 'react';
import classNames from 'classnames';
import { LinkInfo } from '../../types';
import IconRenderer, { IconDefinition } from '../IconRenderer';
import LinkInfoRenderer from '../LinkInfoRenderer';
import styles from './styles/styles.module.scss';

export type TabDefinition = {
  id: string;
  icon?: IconDefinition<'orientation'>;
  label: string;
  linkInfo?: LinkInfo;
  testId: string;
};

type TabProps = TabDefinition & {
  active: boolean;
  indicatorPosition?: 'top' | 'bottom';
  onClick: (id: TabDefinition['id']) => void;
  onKeyDown: KeyboardEventHandler<HTMLElement>;
  tabRef: (el: HTMLElement) => void;
};

const Tab = ({ active, id, icon, indicatorPosition = 'bottom', label, linkInfo, onClick, onKeyDown, tabRef, testId }: TabProps): JSX.Element => {
  const content = icon ? (
    <>
      <IconRenderer icon={icon} defaults={{ color: 'currentColor', size: 'md' }} />
      <span>{label}</span>
    </>
  ) : (
    <span>{label}</span>
  );

  const onTabClick = () => {
    onClick(id);
  };

  const classes = classNames(styles.tab, active && styles.active, { [styles.topIndicator]: indicatorPosition === 'top' });
  const tabIndex = active ? 0 : -1;

  if (linkInfo) {
    return (
      <LinkInfoRenderer
        className={classes}
        linkInfo={linkInfo}
        onClick={onTabClick}
        onKeyDown={onKeyDown}
        role="tab"
        ref={tabRef as React.Ref<HTMLAnchorElement>}
        tabIndex={tabIndex}
        testId={testId}
      >
        {content}
      </LinkInfoRenderer>
    );
  }
  return (
    <button
      className={classes}
      onClick={onTabClick}
      onKeyDown={onKeyDown}
      role="tab"
      ref={tabRef as React.LegacyRef<HTMLButtonElement>}
      tabIndex={tabIndex}
      type="button"
      data-testid={testId}
    >
      {content}
    </button>
  );
};

export default Tab;
