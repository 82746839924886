import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Context from '@alltrails/core/types/Context';
import getHasLegacyPortalAccess from '../utils/getHasLegacyPortalAccess';

const useHasLegacyPortalAccess = () => {
  const context = useSelector((state: { context: Context }) => state.context);
  const hasLegacyPortalAccess = useMemo(() => getHasLegacyPortalAccess(context), [context]);

  return hasLegacyPortalAccess;
};

export default useHasLegacyPortalAccess;
