import Script from 'next/script';
import { useGoogleHandler, LoginResponse } from '@alltrails/user-auth';
import logOneTapDisplayed from '@alltrails/analytics/events/logOneTapDisplayed';
import useSetUser from '@alltrails/context/hooks/useSetUser';
import useUser from '@alltrails/context/hooks/useUser';
import { useEffect, useState } from 'react';
import { isAndroid } from '@alltrails/core';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import { InstallBannerState } from '@alltrails/install-prompt/slices/installBanner';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import UpsellTrigger from '@alltrails/analytics/enums/UpsellTrigger';
import { useExperiments } from '@alltrails/experiments';
import styles from './GoogleOneTap.module.scss';

const GenericPostAuthUpsellModal = dynamic(() => import('@alltrails/upsell/components/GenericPostAuthUpsell/PostAuthUpsellModal'), {
  ssr: false
});

type Props = {
  pageViewLimitReached?: boolean;
};

export default function GoogleOneTap({ pageViewLimitReached }: Props) {
  const googleHandler = useGoogleHandler();
  const setUser = useSetUser();
  const user = useUser();
  const isMobile = useIsMobileSizedScreen();
  const [isMounted, setIsMounted] = useState(false);
  const isInstallBannerOpen = useSelector((state: { installBanner: InstallBannerState }) => state.installBanner?.isOpen);
  const [didAuthWithOneTap, setDidAuthWithOneTap] = useState(false);
  const [didShowUpsell, setDidShowUpsell] = useState(false);
  const experiments = useExperiments();

  const shouldShowOneTap = isMounted && !user && (!isMobile || isAndroid());

  const isPeakSoftLaunch =
    experiments['rm-peak-soft-launch']?.value === 'peak_default' || experiments['rm-peak-soft-launch']?.value === 'plus_default';

  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);

  async function callback(resp: any) {
    try {
      const response: LoginResponse = await googleHandler(resp.credential, 'login', undefined, true);
      setUser(response.user);

      // Desktop upsell flow
      if (isPeakSoftLaunch) {
        if (!isMobile && response.user.subscriptionTier !== 'peak') {
          setIsUpsellModalOpen(true);
        }
      } else {
        if (!isMobile && response.user.subscriptionTier === 'base') {
          setIsUpsellModalOpen(true);
        }
      }
      setDidAuthWithOneTap(true);
      window.google?.accounts.id.cancel(); // google can be undefined on mobile web
    } catch (e) {
      // we don't have a design spec for showing errors so we ignore them for now
    }
  }

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    // Mobile upsell flow (only show upsell after the banner is closed)
    const isBannerClosedOrUninitialized = !isInstallBannerOpen || isInstallBannerOpen === 'uninitialized';
    const isInUpsellTier = isPeakSoftLaunch ? user?.subscriptionTier !== 'peak' : user?.subscriptionTier === 'base';
    const isEligibleForUpsell = isMobile && isBannerClosedOrUninitialized && didAuthWithOneTap && !didShowUpsell && isInUpsellTier;

    if (isEligibleForUpsell) {
      setIsUpsellModalOpen(true);
      setDidShowUpsell(true);
    }
  }, [isInstallBannerOpen, didAuthWithOneTap, didShowUpsell, isMobile, user?.pro, isPeakSoftLaunch, user?.subscriptionTier]);

  return (
    <>
      {shouldShowOneTap && (
        <>
          <div id="g_one_tap" className={styles.one} />
          <Script
            async
            onLoad={() => {
              window.google.accounts.id.initialize({
                callback,
                cancel_on_tap_outside: false,
                client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
                prompt_parent_id: 'g_one_tap',
                use_fedcm_for_prompt: true
              });

              if (!pageViewLimitReached) {
                window.google.accounts.id.prompt((notification: any) => {
                  if (notification.isDisplayMoment() && notification.isDisplayed()) {
                    logOneTapDisplayed();
                  }
                });
              }
            }}
            src="https://accounts.google.com/gsi/client"
          />
        </>
      )}
      {isUpsellModalOpen && (
        <GenericPostAuthUpsellModal
          isOpen={isUpsellModalOpen}
          onCloseRequest={() => setIsUpsellModalOpen(false)}
          trigger={CarouselDisplayTrigger.CompleteAuthentication}
          upsellTrigger={UpsellTrigger.CompleteAuthentication}
        />
      )}
    </>
  );
}
